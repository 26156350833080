$top-navbar-bg-color: #2F3E9E;
$top-navbar-text-color: #C4C9E8;
$logo-color: #fff;
$logo-ng-color: #fff;

$main-bg-color: #f5f5f5;
$main-text-color: #565656;

$info-content-bg-color: #3E50B4; 
$info-content-icon-color: $top-navbar-text-color;

$messages-ddl-link-active-border-color: $top-navbar-bg-color;

$user-menu-bg-color: $top-navbar-bg-color;
$user-menu-text-color: $top-navbar-text-color;

$side-chat-item-color: #C4C9E8;
$side-chat-item-bg-color: #3E50B4;
$side-chat-item-bottom-border-color: #f5f5f5;

$sidebar-bg-color: #3E50B4; 
$active-link-bg-color: #526CFD;
$sidebar-item-color: #C4C9E8;
$sidebar-item-hover-color: #fff;
$sidebar-item-hover-bg-color: $top-navbar-bg-color;
$sidebar-item-bottom-border-color: $top-navbar-bg-color;
$sidebar-item-bottom-border-shadow-color: #fff;
$sidebar-subitem-bg-color: #000;

$mini-sidebar-tooltip-color: #fff;
$mini-sidebar-tooltip-bg-color: #3E50B4;
$mini-sidebar-tooltip-arrow-color: #3E50B4; 

$mini-horizontal-menu-tooltip-color: #fff;
$mini-horizontal-menu-tooltip-bg-color: #000;
$mini-horizontal-menu-tooltip-arrow-color: #000;

$footer-bg-color: #2F3E9E; 

$box-shadow-color: $sidebar-bg-color;

$card-header-color: #f2f2f2;
$card-header-bg-color: #f3f3f3;

$login-window-bg-color: #526CFD;
$login-header-bg-color: #2F3E9E;
$login-text-color: #F5F5F5;


$widget-controls-color: #a6a6a6;

$form-control-focus-border-color: #3E50B4;


$primary-color: #3E50B4;
$dark-primary-color: #2F3E9E;
$success-color: #4BAE4F;
$dark-success-color: #378D3B;
$info-color: #00BBD3;
$dark-info-color: #0096A6;
$warning-color: #FE9700; 
$dark-warning-color: #F47B00;
$danger-color: #F34235;
$dark-danger-color: #D22E2E;
$gray-color: #9D9D9D;
$dark-gray-color: #606060;
$inverse-color: #292B2C;
$dark-inverse-color: #1C1E1F;

$breadcrumb-color: #3E50B4;